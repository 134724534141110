



























import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics } from '@/includes/Api/Statistics.api'

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

@Component({
  components: {
    VueApexCharts,
    TableExportButtons,
    HelpMessage
  }
})
export default class MessagesMetaChart extends Vue {
  @Prop() rawData!: ChatStatistics['message_count_by_day']

  @Prop() period!: { from: string, to: string }

  series: ApexChartSeries | null = null

  options: ApexOptions | null = null

  get fromTo() {
    return fromToTicks(this.period.from, this.period.to)
  }

  get tableData() {
    const dates = this.fromTo.timeTicks.map(t => ({ [this.$t('statistics_table_date_title').toString()]: t }))

    return dates.reduce((acc: Array<Record<string, number | string>>, current) => {
      const record = this.rawData.find(r => r.date === current[this.$t('statistics_table_date_title').toString()])

      acc.push({
        ...current,
        [this.$t('metric_message_count_title').toString()]: record?.message_count ?? 0,
        [this.$t('metric_comments_count_title').toString()]: record?.comments_count ?? 0,
        [this.$t('metric_reply_message_count_title').toString()]: record?.reply_message_count ?? 0,
        [this.$t('metric_edited_message_count_title').toString()]: record?.edited_message_count ?? 0,
      })

      return acc
    }, [])
  }

  mounted() {
    seriesByMetricInList(this.$i18n, this.rawData, [
                           "message_count",
                           "comments_count",
                           "reply_message_count",
                           "edited_message_count",
                         ],
                         this.fromTo,
                         'date'
    )
      .calcSeries([
        { type: 'line', name: this.$t('total_message_count_msg_meta_chart_title').toString() },
        { type: 'column' },
        { type: 'column' },
        { type: 'column' },
      ])
      .then(res => {
        this.series = res
        this.options = {
          chart: {
            animations: {
              enabled: false
            },
            defaultLocale: this.$i18n.locale,
            locales: ApexLocales,
            zoom: {
              allowMouseWheelZoom: false
            }
          },
          colors: [ 'rgba(var(--a-success), 1)', 'rgba(var(--a-warning), 1)', 'rgba(var(--a-dark), 1)', 'rgba(var(--a-danger), 1)', 'rgba(var(--a-primary)' ],
          dataLabels: {
            enabled: false
          },
          yaxis: [
            {
              opposite: true,
              seriesName: this.$t('metric_message_count_title').toString(),
              title:{
                text: this.$t('total_message_count_msg_meta_chart_title').toString()
              }
            },
            { seriesName: this.$t('metric_comments_count_title').toString(), show: true },
            { seriesName: this.$t('metric_comments_count_title').toString(), show: false },
            { seriesName: this.$t('metric_comments_count_title').toString(), show: false },
          ],
          tooltip: {
            enabled: true
          },
          stroke: {
            curve: 'smooth',
            width: 2
          },
          xaxis: {
            type: 'datetime',
            categories: this.fromTo.timeTicks
          },
          legend: {
            clusterGroupedSeries: false
          }
        }
      })
  }
}





















































































































import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import MixedUsersStatistic from "@/views/chat/statistics/components/mainStatistics/mixedUsersStatistic.vue";
import MsgUsersCount from "@/views/chat/statistics/components/mainStatistics/msgUsersCount.vue";
import UsersRetention from "@/views/chat/statistics/components/mainStatistics/usersRetention.vue";
import EngagementRate from "@/views/chat/statistics/components/mainStatistics/engagementRate.vue";
import UsersActivityHeatmap from "@/views/chat/statistics/components/mainStatistics/usersActivityHeatmap.vue";
import MsgCountHeatmap from "@/views/chat/statistics/components/mainStatistics/msgCountHeatmap.vue";
import NewUsers from "@/views/chat/statistics/components/mainStatistics/newUsers.vue";
import RetentionChartUsers from "@/views/chat/statistics/components/mainStatistics/retentionChartUsers.vue";
// import RetentionChartMsg from "@/views/chat/statistics/components/mainStatistics/retentionChartMsg.vue";
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { errorNotification } from '@/includes/NotificationService'
import SmallChartsStatWrapper from '@/views/chat/statistics/components/mainStatistics/SmallChartsStatWrapper.vue'
import StatisticsApi, { ChatStatistics } from '@/includes/Api/Statistics.api'
import ConvertedUsersChart from '@/views/chat/statistics/components/mainStatistics/ConvertedUsersChart.vue'
import MessagesMetaChart from '@/views/chat/statistics/components/mainStatistics/MessagesMetaChart.vue'
import UsersMessagesMetaChart from "./components/mainStatistics/UsersMessagesMetaChart.vue";
import MobileLandscapeTriggerLayout from "../../../components/MobileLandscapeTriggerLayout.vue";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import moment from "moment";
import { Component, Watch } from 'vue-property-decorator'
import Vue from "vue";

@Component({
  computed: {
    SitePermissionEnum() {
      return SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    MessagesMetaChart,
    UsersMessagesMetaChart,
    ConvertedUsersChart,
    SmallChartsStatWrapper,
    MobileLandscapeTriggerLayout,
    // RetentionChartMsg,
    RetentionChartUsers,
    NewUsers,
    MsgCountHeatmap,
    UsersActivityHeatmap,
    EngagementRate,
    UsersRetention,
    MsgUsersCount,
    MixedUsersStatistic,
    PageTitle,
    SetPeriod,
    NotAvailableOptionsOverlay,
  }
})
export default class Statistics extends Vue {
  period = {
    from: moment().add(-1, 'months').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  }

  rawDataNew: ChatStatistics | null = null

  isLoading = false

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    this.loadStatistics()
  }

  getPeriodStat(): void {
    const from = moment(this.period.from)
    const to = moment(this.period.to)

    const diff = to.diff(from, 'day')

    if (from.isSameOrBefore(to) && to.isBefore(moment())) {
      if (diff < 180) {
        this.isLoading = true

        this.loadStatistics()
      } else {
        errorNotification(this.$t('statistics_period_limit_is_exceeded').toString())
      }
    } else {
      errorNotification(this.$t('statistics_period_is_not_correct').toString())
    }
  }

  loadStatistics() {
    if (!this.$store.getters.botNotAdmin) {
      this.isLoading = true

      StatisticsApi.getChatStat('tg', {
        to: this.period.to,
        from: this.period.from,
        chat_id: this.$store.state.chatState.chat.chat_id,
      })
        .then(res => {
          this.rawDataNew = res.data
        })
        .catch(errorNotification)
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  mounted(): void {
    this.$baseTemplate.saveButton.hide();

    if (this.$store.getters.canStatistics) {
      this.loadStatistics()
    }
  }
}

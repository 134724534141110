



























import { ChatStatistics } from "@/includes/Api/Statistics.api";
import { ChartInfo } from '../../logic/types'
import { ApexLocales } from '../../logic/defaultApexSettings'

import { UseFields } from "piramis-base-components/src/components/Pi";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";

import { Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  }
})
export default class RetentionChartUsers extends UseFields {
  @Prop() rawData!: ChatStatistics['message_count_by_day']

  @Prop() period!: { from: string, to: string }

  users: ChartInfo | null = null

  getOptions(labels: Array<string>): ApexOptions {
    return {
      chart: {
        animations: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales,
        zoom: {
          allowMouseWheelZoom: false
        }
      },
      colors: [
        'rgba(var(--a-danger), 1)',
        'rgba(var(--a-success), 1)',
        'rgba(var(--a-warning), 1)',
      ],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        categories: labels
      },
    }
  }

  get fromTo() {
    return fromToTicks(this.period.from, this.period.to)
  }

  mounted(): void {
    seriesByMetricInList(
      this.$i18n,
      this.rawData,
      [ 'young_users_count', 'other_users_count', 'regular_users' ],
      this.fromTo,
      'date'
    )
      .calcSeries([
        { type: 'column', name: this.$t('retention_chart_metric_young_users_count_title').toString() },
        { type: 'column', name: this.$t('retention_chart_metric_other_users_count_title').toString() },
        { type: 'column', name: this.$t('retention_chart_metric_regular_users_title').toString() },
      ])
      .then((res) => {
        this.users = {
          series: res,
          options: this.getOptions(this.fromTo.timeTicks)
        }
      })

  }

  get tableData() {
    const dates = this.fromTo.timeTicks.map(t => ({ [this.$t('statistics_table_date_title').toString()]: t }))

    return dates.reduce((acc: Array<Record<string, number | string>>, current) => {
      const record = this.rawData.find(r => r.date === current[this.$t('statistics_table_date_title').toString()])

      acc.push({
        ...current,
        [this.$t('retention_chart_metric_young_users_count_title').toString()]: record?.young_users_count ?? 0,
        [this.$t('retention_chart_metric_other_users_count_title').toString()]: record?.other_users_count ?? 0,
        [this.$t('retention_chart_metric_regular_users_title').toString()]: record?.regular_users ?? 0,
      })

      return acc
    }, [])
  }
}































import { ChartInfo } from "@/views/chat/statistics/logic/types";
import { TagRowItem } from '@/components/TagRow/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'
import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics, StatisticsCountAndOnlineRecord } from '@/includes/Api/Statistics.api'

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";

import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import { sum } from "lodash";
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import Vue from 'vue'

@Component({
  components: {
    TagsRow,
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  }
})
export default class MixedUsersStatistic extends Vue {
  @Prop() rawData!: ChatStatistics

  @Prop() period!: { from: string, to: string }

  mixedUsers: ChartInfo | null = null

  get totalValues(): Array<TagRowItem> {
    if (this.rawData) {

      const leave = this.getTickValues(this.rawData.enter, 'leave_total')
      const kick = this.getTickValues(this.rawData.enter, 'kick')
      const usersNewDayTotalUsersSum = this.getTickValues(this.rawData.enter, 'enter_total')

      return [
        {
          value: this.rawData.count_and_online.find(r => r.date === moment().add(-1, 'd').format('YYYY-MM-DD'))?.count ?? '-',
          title: 'statistics_users_tick_new_mixed_chart_total_title',
          color: 'rgba(var(--a-primary), 1)',
          icon: 'user'
        },
        {
          value: sum(usersNewDayTotalUsersSum),
          title: 'common_chat_records_new_users',
          color: 'rgba(var(--a-success), 1)',
          icon: 'user-add'
        },
        {
          value: sum(leave),
          title: 'statistics_users_tick_leave_mixed_chart_total_title',
          color: 'rgba(var(--a-dark), 1)',
          icon: 'user-delete'
        },
        {
          value: sum(kick),
          title: 'statistics_users_tick_kicked_mixed_chart_total_title',
          color: 'rgba(var(--a-danger), 1)',
          icon: 'usergroup-delete'
        },
      ]
    }

    return []
  }

  get fromTo() {
    return fromToTicks(this.period.from, this.period.to, { format: 'YYYY-MM-DD' })
  }

  get table() {
    if (this.rawData) {
      const table = this.rawData.enter.reduce((acc: Array<Record<string, string | number>>, current) => {
        const recordPushTo = acc.find(value => value[this.$t('statistics_table_date_title').toString()] === current.date)

        if (recordPushTo) {
          recordPushTo[this.$t('statistics_new_title').toString()] = current?.enter_total
          recordPushTo[this.$t('metric_leave_total_title').toString()] = current?.leave_total
          recordPushTo[this.$t('metric_kick_title').toString()] = current?.kick
        }

        return acc
      }, this.fromTo.timeTicks.map(t => ({
        [this.$t('statistics_table_date_title').toString()]: t,
        [this.$t('statistics_new_title').toString()]: '-',
        [this.$t('metric_leave_total_title').toString()]: '-',
        [this.$t('metric_kick_title').toString()]: '-',
        [this.$t('statistics_total_title').toString()]: '-',
        [this.$t('metric_online_title').toString()]: '-',
      })))

      return this.rawData.count_and_online.reduce((acc: Array<Record<string, string | number>>, current) => {
        const recordPushTo = acc.find(value => value[this.$t('statistics_table_date_title').toString()] === current.date)

        if (recordPushTo) {
          recordPushTo[this.$t('statistics_total_title').toString()] = current?.count
          recordPushTo[this.$t('metric_online_title').toString()] = current?.online
        }

        return acc
      }, table)
    }

    return []
  }

  getTickValues<U extends Object, T extends keyof U>(model: Array<U>, key: T):Array<number | null> {
    return model.map((item:any) => item[key] ?? null)
  }

  mounted(): void {
    const promiseColumnCharts = new Promise<ApexChartSeries>(resolve => {
      seriesByMetricInList(this.$i18n, this.rawData.enter, [ 'enter_total', 'leave_total', 'kick' ], this.fromTo, 'date')
        .calcSeries([
                      { type: 'column', name: this.$t('statistics_new_title').toString() },
                      { type: 'column' },
                      { type: 'column' }
                    ],
                    true)
        .then((res) => resolve(res))
    })

    const metrics = [
      'count',
      this.rawData.count_and_online.some(r => r.online > 0) ? 'online' : undefined,
    ].filter(m => m !== undefined) as Array<keyof Omit<StatisticsCountAndOnlineRecord, 'date'>>

    const promiseLineCharts = new Promise<ApexChartSeries>(resolve => {
      const metricsSeriesInfo = [
        { type: 'line' },
        ...metrics.indexOf('online') !== -1 ? [ {
          type: 'line'
        } ] : [],
      ]

      seriesByMetricInList(this.$i18n, this.rawData.count_and_online, metrics, this.fromTo, 'date')
        .calcSeries(metricsSeriesInfo, true)
        .then((res) => resolve(res))
    })

    Promise.all([ promiseColumnCharts, promiseLineCharts ])
      .then(data => {
        this.mixedUsers = {
          series: [],
          options: { ...this.getOptions(this.fromTo.timeTicks.map(item => moment(item).format('YYYY-MM-DD')), metrics) },
        }

        data.forEach(d => {
          this.mixedUsers!.series.push(...d)
        })
      })
  }

  getOptions(labels: Array<string>, metrics: Array<keyof Omit<StatisticsCountAndOnlineRecord, 'date'>>): ApexOptions {
    const hasOnlineMetric = metrics.indexOf('online') !== -1

    return {
      legend: {
        clusterGroupedSeries: false
      },
      chart: {
        animations: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales,
        zoom: {
          allowMouseWheelZoom: false
        },
      },
      colors: [ 'rgba(var(--a-success), 1)', 'rgba(var(--a-dark), 1)', 'rgba(var(--a-danger), 1)', 'rgba(var(--a-primary)', 'rgba(var(--a-warning), 1)' ],
      dataLabels: {
        enabled: false
      },
      yaxis: [
        { seriesName: this.$t('statistics_new_title').toString(), },
        { seriesName: this.$t('statistics_new_title').toString(), show: false },
        { seriesName: this.$t('statistics_new_title').toString(), show: false },

        ...hasOnlineMetric ? [ {
          opposite: true, seriesName: this.$t('metric_count_title').toString(),
          title: { text: this.$t('mixed_users_chart_total_online_yaxis_title').toString() }
        } ] : [],
        { seriesName: this.$t('metric_count_title').toString(), show: !hasOnlineMetric,
          ...!hasOnlineMetric ? {
            opposite: true,
            title: { text: this.$t('mixed_users_chart_total_yaxis_title').toString() }
          } : {}
        },
      ],
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        categories: labels
      },
    }
  }

}
